<template>
  <v-dialog
    v-model="state"
    :width="tabIndex === 0 ? '480px' : '550px'"
    persistent
  >
    <v-card :disabled="loading">
      <ValidationObserver
        tag="div"
        class="d-flex flex-row flex-grow-1"
        v-slot="{ handleSubmit }"
      >
        <form
          class="d-flex flex-column flex-grow-1"
          @submit.prevent="handleSubmit(addNewBillPayment)"
        >
          <v-chip-group
            mandatory
            class="ml-3 mt-2"
            active-class="primary"
            v-model="tabIndex"
          >
            <v-chip
              v-for="(tab, index) in ['Pay Bill', 'Bill Payment List']"
              color="white"
              class="box-shadow-light"
              label
              small
              :key="index"
            >
              <span class="font font-weight-medium font-size-sm">{{
                tab
              }}</span>
            </v-chip>
          </v-chip-group>
          <v-card-text v-if="tabIndex === 0">
            <ValidationProvider tag="div" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="bill.recordedDate"
                    v-on="on"
                    v-bind="attrs"
                    prepend-icon="event"
                    autofocus
                    readonly
                    label="Choose Date"
                    hint="Select a date to record the bill payment"
                    :error-messages="errors[0]"
                    class=" font font-weight-medium font-size-sm"
                  />
                </template>
                <v-date-picker
                  class=" font font-weight-medium font-size-sm"
                  v-model="bill.recordedDate"
                  :max="new Date().toISOString().substring(0, 10)"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required|decimal"
              class="mt-n2"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="bill.amount"
                prepend-icon="credit_card"
                label="Enter Amount"
                hint="Enter amount the user wants to pay"
                :error-messages="errors[0]"
                class=" font font-weight-medium font-size-sm"
              />
            </ValidationProvider>
            <ValidationProvider tag="div" rules="required" v-slot="{ errors }">
              <v-autocomplete
                :items="paymentType"
                v-model="bill.paymentType"
                return-object
                prepend-icon="account_balance_wallet"
                label="Choose Payment Type"
                :error-messages="errors[0]"
                hint="Choose whether the payment was made via cash or cheque"
                class="font font-weight-medium font-size-sm"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for already available
                      <kbd>prices</kbd>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <span class="font font-weight-medium font-size-sm py-2">
                    {{ item }}</span
                  >
                </template>
              </v-autocomplete>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required|min:10"
              v-slot="{ errors }"
            >
              <v-textarea
                class=" font font-weight-medium font-size-sm"
                prepend-icon="style"
                v-model="bill.comment"
                label="Add comment to this payment"
                hint="Tell us about this payment"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-card-text>
          <v-card-text v-else class="pa-0">
            <v-data-table
              show-expand
              item-key="date"
              :headers="[
                { text: 'Date', value: 'date', align: 'center' },
                { text: 'Amount Paid', value: 'amountPaid', align: 'left' },
                { text: 'Payment Type', value: 'paymentType', align: 'left' }
              ]"
              :items="billPayments"
              :server-items-length="pagination.total"
              :options.sync="options"
              :loading="loading"
              :footer-props="{
                itemsPerPageOptions: [5, 8]
              }"
              class=" font font-weight-medium font-size-sm mt-3"
            >
              <template #item.amountPaid="{item}">
                <v-chip small label class=" font font-weight-bold">
                  {{ item.amountPaid | currencyFormat }}
                </v-chip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div class="d-flex flex-column">
                    <span
                      class="text-uppercase pt-1 font font-weight-medium font-size-sm"
                      >Comment</span
                    >
                    <p class="font font-weight-medium font-size-sm">
                      {{ item.comment }}
                    </p>
                  </div>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="close" text>
              <span
                class="font text-capitalize font-weight-medium font-size-sm"
              >
                Cancel
              </span>
            </v-btn>
            <v-btn
              v-if="tabIndex !== 1"
              type="submit"
              :loading="loading"
              :disabled="loading"
              color="primary"
            >
              <span
                class="font text-capitalize font-weight-medium font-size-sm"
              >
                Save
              </span>
            </v-btn>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { currencyFormat } from "../../../filters/currencyFormat";
import { required, min } from "vee-validate/dist/rules";
import { decimals } from "../../../utils/resolvers";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend("min", {
  ...min,
  message: "Character must not be less than 10"
});
extend("decimal", decimals);

import { mapGetters } from "vuex";
export default {
  name: "AddNewPrice",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: { type: Boolean, default: false },
    loading: {
      type: Boolean,
      default: false
    },
    currency: {
      type: Array,
      default: () => []
    },
    availablePrices: {
      type: Array,
      default: () => []
    },
    billPayments: {
      type: Array,
      default: () => []
    },
    paginate: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      resetFormState: "getResetFormState"
    })
  },
  filters: {
    currencyFormat
  },
  data: () => ({
    menu: false,
    bill: {
      amount: null,
      paymentType: null,
      comment: "",
      recordedDate: null
    },
    pagination: {
      total: 0,
      page: 1,
      limit: 5
    },
    tabIndex: 0,
    paymentType: ["CASH", "CHEQUE"],
    options: {}
  }),
  watch: {
    resetFormState(value) {
      if (value) {
        this.tabIndex = 0;
        this.bill = { amount: null, paymentType: null, comment: null };
      }
      setTimeout(() => {
        this.$store.dispatch("resetFormState", false, { root: true });
      }, 2000);
    },
    paginate(payload) {
      this.pagination = { ...payload };
    },
    options(value) {
      const { page, itemsPerPage } = value;
      const query = `?page=${page}&size=${itemsPerPage}`;
      this.$store.dispatch("payments/listPaidBills", { id: query });
    }
  },
  methods: {
    addNewBillPayment() {
      const { amount, ...rest } = this.bill;

      this.$store.dispatch("payments/makePayment", {
        ...rest,
        amountPaid: Number(amount),
        startDate: "2021-08-01"
      });
    },
    close() {
      this.$emit("close", "details");
    },
    fireSnackBarMessage() {
      this.$store.commit("UPDATE_SNACKBAR", true, { root: true });
      this.$store.commit("UPDATE_MESSAGE", "Invalid value provided", {
        root: true
      });
      this.$store.commit("UPDATE_STATUS", "red", { root: true });

      setTimeout(() => {
        this.$store.commit("UPDATE_SNACKBAR", false, { root: true });
      }, 2000);
    }
  }
};
</script>
